body {
  margin: 0;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: local("Gilroy-SemiBold"),
    url(./fonts/gilroy/Gilroy-SemiBold.ttf) format("truetype");
}

/* @font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"),
    url(./fonts/Gilroy-ExtraBold.otf) format("truetype");
} */

@font-face {
  font-family: "Gilroy-RegularBold";
  src: local("Gilroy-RegularBold"),
    url(./fonts/gilroy/Gilroy-Bold.ttf) format("truetype");
}

/* @font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"), url(./fonts/Gilroy-Light.otf) format("truetype");
} */

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Gilroy-ExtraBold"),
    url(./fonts/gilroy/Gilroy-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url(./fonts/gilroy/Gilroy-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url(./fonts/gilroy/Gilroy-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Source Sans Pro-Regular";
  src: local("Source Sans Pro-Regular"),
    url(./fonts/source-sans-pro/SourceSansPro-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Source Sans Pro-Bold";
  src: local("Source Sans Pro-Bold"),
    url(./fonts/source-sans-pro/SourceSansPro-Bold.ttf) format("truetype");
}
